<template>
    <div class="drop-box" @drop="handleDrop" @dragover="handleDragOver" @click="handleClick">
        Click or drop file here
        <li v-for="file in files" :key="file">{{ file.name }}</li>
        Chose {{ files.length }} file(s)
    </div>
</template>

<script>
export default {
    name: "DropBox",
    data() {
        return {
            files: [],
        };
    },
    methods: {
        handleDrop(ev) {
            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();
            this.files = [];

            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...ev.dataTransfer.items].forEach((item) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        this.files.push(file);
                    }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...ev.dataTransfer.files].forEach((file) => {
                    this.files.push(file);
                });
            }
            this.handleUpload();
        },
        handleDragOver(ev) {
            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();
        },
        handleFiles(file) {
            console.log(file);
        },
        handleClick() {
            let input = document.createElement("input");
            input.type = "file";
            input.multiple = true;
            input.onchange = () => {
                // you can use this method to get file and perform respective operations
                this.files = [...input.files];
                this.handleUpload();
            };
            input.click();
        },
        handleUpload() {
            // const API = "https://bili-web.merlyn.workers.dev";
            const API = "https://bili-web.merlyn.workers.dev";
            let formData = new FormData();
            this.files.forEach((file) => {
                formData.append("files", file);
                formData.append("fileNames", file.name);
            });
            let xhr = new XMLHttpRequest();
            xhr.open("POST", API, true);
            xhr.send(formData);
        },
    },
};
</script>

<style>
.drop-box {
    width: 200px;
    height: 300px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 8px;
    box-sizing: border-box;
    text-align: center;
}
</style>